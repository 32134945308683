import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = (props) => {
	const { title, description } = props;
	const { pathname } = useLocation();
	// eslint-disable-next-line no-use-before-define
	const { site } = useStaticQuery(query);
	const { baseURL } = site.siteMetadata;
	const url = `${baseURL}${pathname}`;

	return (
		<Helmet>
			<html lang="en" />
			<title>{title}</title>
			<link rel="canonical" href={url} />
			{description && <meta name="description" content={description} />}

			<meta property="og:url" content={url} />
			<meta property="og:title" content={title} />
			{description && (
				<meta property="og:description" content={description} />
			)}

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={title} />
			{description && (
				<meta name="twitter:description" content={description} />
			)}
		</Helmet>
	);
};

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
};

export default SEO;

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				title
				baseURL
			}
		}
	}
`;
